/* eslint-disable camelcase */
import { search as recommendationAiSearch } from './gtagForRecommendationAi';

const analytics = {
  logEvent: (event, data) => {
    if (window && window.dataLayer) {
      window.dataLayer.push({
        event,
        ...data,
      });
    }
  },
};

export const set_user_id = (userId) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      user_id: userId
    });
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const page_view = (page_path) => {
  if (page_path) {
    analytics.logEvent('page_view', {
      // page_location: 'https://example.com/about',
      page_path,
      // page_title: 'About'
    });
  }
};

export const search = (search_term, other_params) => {
  if (search_term) {
    recommendationAiSearch(search_term);

    analytics.logEvent('search', {
      search_term,
      ...(other_params || {})
    });
  }
};

export const login = (method) => {
  if (method) {
    analytics.logEvent('login', {
      method,
    });
  }
};

export const sign_up = (method) => {
  if (method) {
    analytics.logEvent('sign_up', {
      method,
    });
  }
};

// https://developers.google.com/gtagjs/reference/event#view_promotion
export const view_promotion = (promotions) => {
  if (promotions) {
    analytics.logEvent('view_promotion', {
      promotions,
    });
  }
};

/* below not yet implemented */

export const exception = (description) => {
  if (description) {
    analytics.logEvent('exception', {
      description,
      fatal: false,
    });
  }
};

// https://developers.google.com/gtagjs/reference/event#timing_complete
export const timing_complete = (name, value) => {
  if (name && value) {
    analytics.logEvent('timing_complete', {
      name,
      value,
    });
  }
};

// https://developers.google.com/gtagjs/reference/event#view_search_results
export const view_search_results = (search_term, items) => {
  if (search_term && items) {
    analytics.logEvent('view_search_results', {
      search_term,
      items,
    });
  }
};

// https://developers.google.com/gtagjs/reference/event#share
export const share = (method, content_type, content_id) => {
  if (method && content_type && content_id) {
    analytics.logEvent('share', {
      method,
      content_type,
      content_id,
    });
  }
};

// https://developers.google.com/gtagjs/reference/event#select_content
export const select_content = (content_type, items, promotions) => {
  if (content_type) {
    analytics.logEvent('select_content', {
      content_type,
      items,
      promotions,
    });
  }
};

// https://developers.google.com/gtagjs/reference/event#view_item
export const view_item = (items) => {
  if (items) {
    analytics.logEvent('view_item', {
      items,
    });
  }
};

// https://developers.google.com/gtagjs/reference/event#view_item_list
export const view_item_list = (items) => {
  if (items) {
    analytics.logEvent('view_item_list', {
      items,
    });
  }
};
