import { ThemeProvider } from "@material-ui/core/styles";
import "animate.css/animate.min.css";
import { appWithTranslation } from "next-i18next";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { RecoilRoot } from "recoil";
import "../assets/css/react-demo.css";
import "../assets/scss/nextjs-material-kit-pro.scss";
import Bugsnag from "../services/bugsnag";
// eslint-disable-next-line camelcase
import { page_view } from "../services/gtag";
import "../styles/global.scss";
import theme from "../styles/theme";
import initAuth from "../utilities/initAuth";
import Error from "./_error";

initAuth();
const ErrorBoundary = Bugsnag.getPlugin("react");

// eslint-disable-next-line react/prop-types
const App = ({ Component, pageProps }) => {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url) => {
      page_view(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <RecoilRoot>
      <ErrorBoundary FallbackComponent={Error}>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=6.0"
          />
        </Head>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          {/* <CssBaseline /> */}
          <Component {...pageProps} />
        </ThemeProvider>
      </ErrorBoundary>
    </RecoilRoot>
  );
};

export default appWithTranslation(App);
