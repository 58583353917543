import PropTypes from "prop-types";
import Bugsnag from "../services/bugsnag";

const Error = ({ statusCode }) => <p>{statusCode}</p>;

Error.getInitialProps = async (ctx) => {
  const { res, err } = ctx;
  if (err) Bugsnag.notify(err);

  let statusCode = null;
  if (res) {
    ({ statusCode } = res);
  } else if (err) {
    ({ statusCode } = err);
  }
  return {
    statusCode,
  };
};

Error.defaultProps = {
  statusCode: null,
};

Error.propTypes = {
  statusCode: PropTypes.number,
};

export default Error;
